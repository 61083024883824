import moment from 'moment'
import {PERMANENT_GUEST_EXPIRY_DATE_ISO} from '../constants/AppConstants'

export function formatDate(inputdate) {
  let date = new Date(inputdate)
  let Month = ('0' + (date.getMonth() + 1)).slice(-2)
  return Month + '/' + date.getDate() + '/' + date.getFullYear()
}

export function formatHours(inputdate) {
  let date = new Date(inputdate)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  if (minutes != null) {
    minutes = minutes < 10 ? '0' + minutes : minutes
    return hours + ':' + minutes + ampm
  }

  return hours + ampm
}

export function formatDateMMDDYYYY(dateTime) {
  return moment(new moment(dateTime)).format('MM/DD/YYYY')
}

export function formatTime(dateTime) {
  return moment(dateTime).format('h:mma')
}

/**
 * Warning: Timezones might getchya. i.e., isToday('2021-01-01') in an EST browser will appear to be 2020-12-31 GMT-0500
 * @param {string|Date|moment} someDate
 * @returns {boolean}
 */
export function isToday(someDate) {
  someDate = someDate instanceof moment ? someDate.toDate() : someDate instanceof Date ? someDate : new Date(someDate)
  const today = new Date()
  return (
    someDate.getDate() === today.getDate() && someDate.getMonth() === today.getMonth() && someDate.getFullYear() === today.getFullYear()
  )
}

/**
 * @param {string|Date} someDate
 * @returns {boolean}
 */
export function isGuestDatePermanent(someDate) {
  someDate = someDate instanceof Date ? someDate : new Date(someDate)
  return someDate.toISOString() === PERMANENT_GUEST_EXPIRY_DATE_ISO
}

/**
 * @param {string|Date} startDate
 * @param {string|Date} endDate
 * @param {boolean?} isTimeConverted
 * @returns {String}
 */
export function formatTimeRanges(startDate, endDate, isTimeConverted) {
  let startTimeDetails = startDate
  let endimeDetails = endDate
  if (!isTimeConverted) {
    startTimeDetails = moment(startDate).format('LT')
    endimeDetails = moment(endDate).format('LT')
  }

  let timeDetails = startTimeDetails + ' - ' + endimeDetails
  if (timeDetails.split('AM').length - 1 > 1 || timeDetails.split('PM').length - 1 > 1) {
    startTimeDetails = startTimeDetails.substring(0, startTimeDetails.length - 3)
    timeDetails = startTimeDetails + ' - ' + endimeDetails
  }
  return timeDetails
}

/**
 * @param {Array<{start: Date, end: Date}>} timeRanges -- should already be sorted ASCENDING
 * @param {number} blockSize -- block size in Minutes
 * @returns {Array<Date>}
 */
export function timeRangesToStartTimes(timeRanges, blockSize) {
  return timeRanges.reduce((retVal, t) => {
    let current = moment(t.start)
    let end = moment(t.end)

    while (current < end) {
      // if there isn't a full block size left between the current start and end,
      let nextStart = moment(current).add(blockSize, 'minutes')
      if (nextStart > end) {
        return retVal
      }

      // if there is at least one block size left, we add it to our retVal array
      retVal.push(current.toDate())
      current = nextStart
    }

    return retVal
  }, [])
}

export function getDateFromDateString_IgnoreTime(dateString) {
  return dateString.slice(0, 10)
}

/**
 * @returns {String}
 */
export function getTimeZoneAbbreviation() {
  const date = new Date()
  return date.toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ').pop() // Gets the last part which is the abbreviation
}
