import React from 'react'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import {Typography as TypographyStyle, Colors} from './../../assets/styles'
import SelectInput from '../SelectInput'
import AmenityConstants from './AmenitiesConstants'
import ImageGallerySelector from '../ImageGallerySelector'
import DashboardToggle from '../DashboardToggle'
import ManyClosuresInput from './ManyClosuresInput'
import ManyGeneralAvailabilityInput from './ManyGeneralAvailabilityInput'
import NumberInput from '../NumberInput'
import PropTypes from 'prop-types'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'
import {spaceMedium} from '../../assets/styles/spacing'
import UnitAndMagnitudeInput from './UnitAndMagnitudeInput'
import {pluralizeWord} from '../../Utils/StringFormatter'
import {useSelector} from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    ...TypographyStyle.CaptionHeader,
    lineHeight: '19px',
    color: Colors.rxrRedColor,
  },
  title: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    color: Colors.rxrBlackColor,
  },
  headerTitle: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    marginTop: '14px',
    marginBottom: '30px',

    fontSize: 40,
    color: '#0C4067',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
}))

// constructs a select options array in the form of [..., {value: 720, label: '12 hours'}, ...] for values 1 to 40, with value 0 having label 'No limit'
const WEEKLY_LIMIT_OPTIONS = [...new Array(41)].map((e, i) => ({
  value: i * 60,
  label: i === 0 ? 'No limit' : pluralizeWord('hour', i, true),
}))

function calculateTimeRange(minTimeSelected, interval) {
  let times = [] // time array
  let tt = parseInt(minTimeSelected) || 15 // start time
  for (let i = 0; tt <= 8 * 60; i++) {
    let obj = {value: null, label: null}

    let hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    let mm = tt % 60 // getting minutes of the hour in 0-55 format

    if (mm === 0) {
      obj.value = '' + tt
      obj.label = (hh % 12) + ' hour'
    } else if (hh) {
      obj.value = '' + tt
      obj.label = (hh % 12) + ':' + ('0' + mm).slice(-2)
    } else {
      obj.value = '' + tt
      obj.label = mm + ' minutes'
    }
    times[i] = obj
    if (tt === 8 * 60) {
      times[i + 1] = {value: 1440, label: 'All day'}
    }
    tt = tt + interval
  }
  return times
}

function AmenityForm(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>{AmenityConstants.ALL_FIELDS_REQUIRED}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_DETAILS}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Amenity name"
            placeholder=""
            isRequired={true}
            maxLength={120}
            onChange={val => props.updateForm({label: val})}
            value={props.label}
            error={props.invalidItems.includes('label')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ImageGallerySelector
            maxPhotos={3}
            value={props.attachments}
            onChange={v => props.updateForm({attachments: v})}
            isRequired={true}
            error={props.invalidItems.includes('attachments')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput
            label="Description"
            placeholder=""
            rows={4}
            rowsMax={4}
            isRequired={true}
            maxLength={10000}
            onChange={val => props.updateForm({description: val})}
            value={props.description}
            error={props.invalidItems.includes('description')}
          />
        </Grid>
        <Grid item xs={0} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput
            label="Amenity rules for residents"
            placeholder=""
            helperText="10,000 character limit"
            rows={4}
            rowsMax={4}
            maxLength={10000}
            onChange={val => props.updateForm({rules: val})}
            value={props.rules}
          />
        </Grid>
        <Grid item xs={12}>
          <DashboardToggle
            label={'Residents must agree to rules before booking'}
            value={props.mustAgreeToRules}
            onChange={isSelected => props.updateForm({mustAgreeToRules: isSelected})}
          />
        </Grid>
        <Grid item xs={12}>
          <DashboardToggle
            label={`Ignore 'book in advance by' rules for staff users`}
            value={props.ignoreBookInAdvanceForStaff}
            onChange={isSelected => props.updateForm({ignoreBookInAdvanceForStaff: isSelected})}
          />
        </Grid>

        <UnitAndMagnitudeInput
          label={'Resident has to cancel by'}
          value={props.advancedNoticeCancel}
          onChange={val => props.updateForm({advancedNoticeCancel: val})}
          isRequired={true}
          hasError={props.invalidItems.includes('advancedNoticeCancel')}
        />
        <Grid item xs={0} md={6}></Grid>

        <UnitAndMagnitudeInput
          label={'Book in advance by'}
          value={props.advancedNoticeRequest}
          onChange={val => props.updateForm({advancedNoticeRequest: val})}
          isRequired={true}
          hasError={props.invalidItems.includes('advancedNoticeRequest')}
        />
        <Grid item xs={0} md={6}></Grid>

        <UnitAndMagnitudeInput
          label={'Reservation buffer time'}
          value={props.reservationPadding}
          onChange={val => props.updateForm({reservationPadding: val})}
          isRequired={true}
          hasError={props.invalidItems.includes('reservationPadding')}
        />
        <Grid item xs={0} md={6}></Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_RULES}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <NumberInput
            label="Amenity maximum occupancy"
            min={1}
            onChange={val => props.updateForm({maxOccupancy: val})}
            value={props.maxOccupancy}
            isRequired={true}
            integerOnly={true}
            error={props.invalidItems.includes('maxOccupancy')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <NumberInput
            label="Maximum guests per booking"
            min={0}
            helperText={'A value of 0 means the resident cannot bring guests'}
            onChange={val => props.updateForm({maxPartySize: val + 1})}
            value={props.maxPartySize - 1}
            isRequired={true}
            integerOnly={true}
            error={props.invalidItems.includes('maxPartySize')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                minReservationDuration: val,
              })
            }
            label={'Minimum reservation duration'}
            isRequired={true}
            options={AmenityConstants.MINIMUM_RESERVATION_OPTIONS}
            value={'' + props.minReservationDuration}
            error={props.invalidItems.includes('minReservationDuration')}
          />
        </Grid>

        <Grid item xs={0} md={3}></Grid>

        <Grid item xs={12} md={3}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                maxReservationDuration: val,
              })
            }
            label={'Maximum reservation duration'}
            isRequired={true}
            options={calculateTimeRange(props.minReservationDuration, props.reservationIncrement)}
            value={'' + props.maxReservationDuration}
            error={props.invalidItems.includes('maxReservationDuration')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <NumberInput
            label="Max concurrent reservations"
            min={1}
            helperText="A value of 1 means only 1 reservation can occur at a time"
            onChange={val => props.updateForm({maxConcurrentReservations: val})}
            value={props.maxConcurrentReservations}
            isRequired={true}
            integerOnly={true}
            error={props.invalidItems.includes('maxConcurrentReservations')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            label={'Weekly reservation limit'}
            onChange={v => props.updateForm({weeklyReservationLimit: v})}
            options={WEEKLY_LIMIT_OPTIONS}
            value={props.weeklyReservationLimit || '0'}
            isRequired={true}
            hasError={props.invalidItems.includes('weeklyReservationLimit')}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_RESTRICTIONS}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                needsApproval: val === 'true',
              })
            }
            label={'Needs approval'}
            isRequired={true}
            options={AmenityConstants.NEEDS_APPROVAL_OPTIONS}
            value={'' + props.needsApproval}
            error={props.invalidItems.includes('needsApproval')}
          />
        </Grid>

        <Grid item xs={12}>
          <div style={{marginBottom: spaceMedium, maxWidth: isMobile ? '100%' : '75%'}}>
            <ManyGeneralAvailabilityInput
              value={props.generalAvailability}
              onChange={a => props.updateForm({generalAvailability: a})}
              onRemove={props.onRemoveGeneralAvailability}
              increment={props.reservationIncrement}
              error={props.invalidItems.includes('generalAvailability')}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_CLOSURES}</Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{marginBottom: spaceMedium, maxWidth: isMobile ? '100%' : '75%'}}>
            <ManyClosuresInput value={props.closures} onChange={c => props.updateForm({closures: c})} onRemove={props.onRemoveClosure} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

AmenityForm.propTypes = {
  updateForm: PropTypes.func.isRequired,
  onRemoveGeneralAvailability: PropTypes.func.isRequired,
  onRemoveClosure: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  invalidItems: PropTypes.array.isRequired,
}

export default AmenityForm
