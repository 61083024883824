/*
There's a good amount of duplicated code between this component and the AmenityWeekCalendar.js component
I'm not sure how best to centralize the logic... will need to give it more thought.

In the meantime, if you find a bug in here please confirm it's not also present on the AmenityWeekCalendar component
 */

import React, {useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import useResidentLookup from '../hooks/useResidentLookup'
import moment from 'moment'
import SimpleSpinner from '../SimpleSpinner'
import CalendarWithToolTip, {DUMMY_EVENT_ID} from '../Calendar/CalendarWithToolTip'
// copying the styles from the amenity week calendar
import {useStyles} from './AmenityWeekCalendar'
import {getAmenityDetailsBatch} from '../../lib/queries'
import {loadAmenityReservationsForBuilding} from '../../actions/amenitiesActions'
import AmenitiesConstants from './AmenitiesConstants'
import AmenityConstants from './AmenitiesConstants'
import {Colors} from '../../assets/styles'
import {convertGeneralAvailabilityIntoClosureEvents, splitAndFlattenClosuresByDay} from './amenityCalendarHelpers'
import AmenityReservationEditor from './AmenityReservationEditor'
import CompactAmenityReservationEditor from './CompactAmenityReservationEditor'
import {useLocation} from 'react-router-dom'
import {onboardingElevatorAmenityFilter, getCalendarEventColor} from '../../Utils/amenityUtils'
import Routes from '../../constants/RouteConstants'

function MultipleAmenitiesCalendar(props) {
  const classes = useStyles()
  const [isLoadingDetails, setIsLoadingDetails] = useState(false)
  // closure events are closure records from Dynamo
  const [closureEvents, setClosureEvents] = useState([])
  // general closures are times the amenity is closed due to non-existent GeneralAvailabilities
  // these are going to be an array of arrays where each index in the top array indicates the day of the week
  const [generalClosureEvents, setGeneralClosureEvents] = useState([])
  const [events, setEvents] = useState([])
  const [startAndEndTimes, setStartAndEndTimes] = useState({start: 0, end: 1440})
  const dispatch = useDispatch()
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)
  const amenitiesLookup = useSelector(state => state.Amenities.amenitiesLookup)
  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const {getResident} = useResidentLookup()
  const startOfDay = moment(props.date).startOf('day')
  const dayOfWeek = startOfDay.diff(moment(props.date).startOf('week'), 'days')
  const currentLocation = useLocation()
  const isElevatorAmenity = currentLocation.pathname.includes(Routes.ELEVATOR_CALENDAR_VIEW)

  /**
   * @param {{startAt: Date|string, endAt: Date|string, amenityId: string}} r
   * @return {{column: string, start: number, end: number}}
   */
  const getColumnStartAndEndTimes = r => {
    return {
      column: amenitiesLookup[r.amenityId].label,
      start: moment(r.startAt).diff(moment(r.startAt).startOf('day'), 'minutes'),
      end: moment(r.endAt).diff(moment(r.endAt).startOf('day'), 'minutes'),
    }
  }

  const loadAmenityDetails = async () => {
    const [detailsArr] = await Promise.all([
      getAmenityDetailsBatch(props.amenityIds),
      loadAmenityReservationsForBuilding(dispatch, activeBuildingId),
    ])

    // reduce all the closures from all amenities across all days down to one array
    const allClosures = detailsArr.reduce((agr, details) => agr.concat(splitAndFlattenClosuresByDay(details.closures)), [])

    // we determine the start/end time of the entire grid based on the largest combination of start/end times from general availability
    const se = detailsArr.reduce(
      (agr, details) =>
        details.generalAvailability
          // Removing this, I think it's not true - we don't want the calendar bounds bouncing around when the date changes
          // // we only want to count the general availability on the day of week we're looking at
          // .filter(ga => ga.dayOfWeek === dayOfWeek)
          .reduce((agr, a) => {
            agr.start = Math.min(a.startTime, agr.start)
            agr.end = Math.max(a.endTime, agr.end)
            return agr
          }, agr),
      {start: 1440, end: 0}, // start and end are set at opposite extremes so they'll reduce correctly
    )

    const generalClosuresByDayOfWeek = AmenitiesConstants.daysInAWeek.map(x => [])
    detailsArr.forEach(d => {
      // group our closures by day of the week
      const generalClosuresByDay = AmenityConstants.daysInAWeek.map(() => [])
      // these are already sorted from the query response, but we do assume NO overlaps
      d.generalAvailability.forEach(av => {
        generalClosuresByDay[av.dayOfWeek].push(av)
      })
      generalClosuresByDay.forEach((gAs, dow) => {
        // NOTE: it does not matter that these general closures are not rebuilt when the date input is changed
        // because the calendar component only cares about start times relative to midnight (regardless of day)
        const newClosureEvents = convertGeneralAvailabilityIntoClosureEvents(d.id, se.start, se.end, startOfDay, gAs)
        // make sure each event has the amenity id on it as needed by getColumnStartAndEndTimes
        newClosureEvents.forEach(c => (c.amenityId = d.id))
        generalClosuresByDayOfWeek[dow].push(...newClosureEvents)
      })
    })

    setGeneralClosureEvents(
      generalClosuresByDayOfWeek.map(arr =>
        arr.map(c => ({
          id: c.id,
          ...getColumnStartAndEndTimes(c),
          content: `CLOSED`,
          isDisabled: true,
        })),
      ),
    )
    setStartAndEndTimes(se)
    setClosureEvents(allClosures)
  }

  // when amenity id or date changes, we re-request the usage details
  useEffect(() => {
    setIsLoadingDetails(true)
    setEvents([])
    loadAmenityDetails()
      .catch(err => window.alert(err.message))
      .finally(() => setIsLoadingDetails(false))
  }, [props.amenityId])

  useEffect(() => {
    setEvents(
      Object.values(amenityReservationsLookup)
        .filter(
          r =>
            props.amenityIds.includes(r.amenityId) &&
            moment(r.startAt).isSame(props.date, 'day') &&
            ![AmenitiesConstants.STATE_CANCELLED, AmenitiesConstants.STATE_DECLINED, AmenitiesConstants.STATE_TIMEDOUT].includes(
              r.reservationState,
            ),
        )
        .map(r => {
          const res = getResident(r.residentId)
          const isPending = r.reservationState === AmenityConstants.STATE_REQUESTED
          return {
            id: r.id,
            ...getColumnStartAndEndTimes(r),
            content: (
              <div>
                {isPending && <div className={classes.pendingTag}>Pending Approval</div>}
                {r.staffId ? r.title : `${res.displayName}, ${res.occupancy.unit.number}`}
              </div>
            ),
            color: getCalendarEventColor(r),
          }
        })
        .concat(
          closureEvents
            .filter(c => moment(c.startAt).isSame(props.date, 'date'))
            .map(c => ({
              id: c.id,
              ...getColumnStartAndEndTimes(c),
              content: `Closed for ${c.label}`,
              color: Colors.rxrMediumGreyColor,
              isClosure: true,
            })),
        ),
    )
  }, [amenityReservationsLookup, props.date, closureEvents])

  // columns are the labels of each amenity
  const columns = Object.values(props.amenityIds)
    .filter(id => !!amenitiesLookup[id])
    .map(id => amenitiesLookup[id].label)

  /**
   * @param {CalendarEventConfig} ev
   */
  function eventToReservation(ev) {
    const startAt = moment(startOfDay).add(ev.start, 'minutes').toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    // the amenity they creating for is the one whose label matches the column they clicked in
    const amenityId = Object.values(amenitiesLookup).find(a => a.label === ev.column).id
    return {startAt: startAt, endAt: endAt, amenityId: amenityId}
  }

  const handleUpdateForm = (newData, onUpdateEvent) => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    if (newData.id === DUMMY_EVENT_ID) {
      onUpdateEvent({id: newData.id, ...getColumnStartAndEndTimes(newData)})
    } else {
      // update our reservation event accordingly
      const e = events.find(e => e.id === newData.id)
      if (e) {
        Object.assign(e, getColumnStartAndEndTimes(newData))
        setEvents([...events])
      }
    }
  }

  const finalEventsArray = [...events]
  if (generalClosureEvents[dayOfWeek]) {
    finalEventsArray.push(...generalClosureEvents[dayOfWeek])
  }

  return (
    <div className={classes.container}>
      {isLoadingDetails ? (
        <div className={classes.loading}>
          <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        </div>
      ) : (
        <CalendarWithToolTip
          eventToObject={eventToReservation}
          columns={columns}
          events={finalEventsArray}
          calendarStartTime={startAndEndTimes.start}
          calendarEndTime={startAndEndTimes.end}
          renderEventMenu={(obj, id, onUpdateEvent, onCloseEvent) => {
            return (
              <div className={classes.formContainer}>
                {isElevatorAmenity ? (
                  <CompactAmenityReservationEditor
                    initialValues={obj}
                    amenitiesFilter={onboardingElevatorAmenityFilter}
                    amenityReservationId={id !== DUMMY_EVENT_ID ? id : undefined}
                    onComplete={onCloseEvent ? onCloseEvent : () => {}}
                    onUpdateForm={d => handleUpdateForm(d, onUpdateEvent)}
                  />
                ) : (
                  <AmenityReservationEditor
                    amenityReservationId={id !== DUMMY_EVENT_ID ? id : undefined}
                    initialValues={obj}
                    amenitiesFilter={props.amenitiesFilter}
                    onComplete={onCloseEvent ? onCloseEvent : () => {}}
                    onUpdateForm={d => handleUpdateForm(d, onUpdateEvent)}
                  />
                )}
              </div>
            )
          }}
        />
      )}
    </div>
  )
}

MultipleAmenitiesCalendar.propTypes = {
  amenityIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
}

export default MultipleAmenitiesCalendar
