import React from 'react'
import {useSelector} from 'react-redux'
import {useLocation, withRouter, matchPath, Switch, Route} from 'react-router-dom'
import {IconButton, Grid, Typography, Avatar} from '@mui/material'
import {makeStyles} from '@mui/styles'
import ProfileDetail from './ProfileDetail'
import ProfileTimeline from './ProfileTimeline'
import {AntTab, AntTabs} from '../AntTabs'
import * as Constant from './ResidentConstant'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import UserProfileEditor from './UserProfileEditor'
import Routes from './../../constants/RouteConstants'

import {FILTER_COPY} from '../KnowYourResident/KYRConstants'

import {PageContainer} from '../../assets/styles/layout'
import {rxrTealColor, rxrWhiteColor, rxrBlueColor, rxrSuccessColor} from '../../assets/styles/color'
import AmenityReservation from '../KnowYourResident/AmenityReservation'
import Cleaning from '../KnowYourResident/VendorAppointments/Cleaning'
import DogWalking from '../KnowYourResident/VendorAppointments/DogWalking'
import Massage from '../KnowYourResident/VendorAppointments/Massage'
import PersonalTraining from '../KnowYourResident/VendorAppointments/PersonalTraining'
import MaintenanceDetail from '../KnowYourResident/MaintenanceDetail'
import GuestRegistration from '../KnowYourResident/GuestRegistration'
import IncomingDelivery from '../KnowYourResident/IncomingDelivery'
import OutgoingDelivery from '../KnowYourResident/OutgoingDelivery'
import Payment from '../KnowYourResident/Payment'
import ViewLogActionForm from '../RXOAssistant/ViewLogActionForm'
import {spaceExtraLarge, spaceExtraSmall, spaceLarge, spaceMedium, spaceSmall} from '../../assets/styles/spacing'
import PageHeader from '../PageHeader'
import RXRIcon from '../RXRIcon'
import {Colors} from '../../assets/styles'
import {iconButton} from '../../assets/styles/buttons'
import ResidentsTable from './ResidentsTable'
import ClearableInput from '../ClearableInput'
import {navigationButtonWithArrow} from '../../assets/styles/typography'
import RXRButton from '../RXRButton'
import {getActiveResidents} from '../../Utils/residentUtils'
import ResidentProfileImage from './ResidentProfileImage'
import PetsList from './Pets/PetsList'
import ResidentStatusTag from '../UITags/ResidentStatusTag'

const tabConstants = {
  RESIDENT_PROFILE: 'Resident profile',
  TIMELINE: 'Timeline',
  PETS: 'Pet profiles',
  NOTES: 'Notes',
}

const timelineTitleLookup = {
  [Routes.PROFILE_TIMELINE_AMENITY_RESERVATION]: 'AMENITY RESERVATIONS',
  [Routes.PROFILE_TIMELINE_CLEANING]: 'CLEANING',
  [Routes.PROFILE_TIMELINE_DOG_WALKING]: 'DOG WALKING',
  [Routes.PROFILE_TIMELINE_MASSAGE]: 'SPA SERVICES',
  [Routes.PROFILE_TIMELINE_PERSONAL_TRAINING]: 'PERSONAL TRAINING',
  [Routes.PROFILE_TIMELINE_GUEST_REGISTRATION]: 'GUEST REGISTRATION',
  [Routes.PROFILE_TIMELINE_INCOMING_DELIVERY]: 'PACKAGE DETAILS',
  [Routes.PROFILE_TIMELINE_OUTGOING_DELIVERY]: 'PACKAGE DETAILS',
  [Routes.PROFILE_TIMELINE_MAINTENANCE]: 'MAINTENACE REQUEST',
  [Routes.PROFILE_TIMELINE_PAYMENT]: 'PAYMENT',
  [Routes.PROFILE_TIMELINE_BUILDING_ACTION]: 'RXO ASSISTANT',
}

function UserProfile(props) {
  const classes = useStyles()
  const [filterState, setFilterState] = React.useState(FILTER_COPY)
  const [filterTerm, setFilterTerm] = React.useState('')
  let focusedResidentId
  let focusedPrimaryRecordId
  let timelineTitle = ''

  const currentLocation = useLocation()
  const residentsLookup = useSelector(state => state.Residents.residentsLookup)
  const activeResidents = getActiveResidents(residentsLookup)

  let isProfileSingleMatch = matchPath(currentLocation.pathname, {path: Routes.PROFILE_VIEW_SINGLE})
  let isEditMatch = matchPath(currentLocation.pathname, {path: Routes.PROFILE_EDIT, exact: true})

  if (isProfileSingleMatch) {
    focusedResidentId = isProfileSingleMatch.params.residentId
  }

  // determine if we're on the timeline
  const isTimelineMatch = matchPath(currentLocation.pathname, {path: Routes.PROFILE_VIEW_SINGLE_TIMELINE})
  const isPetsMatch = matchPath(currentLocation.pathname, {path: Routes.PROFILE_VIEW_SINGLE_PETS})

  // if we are, the tab should be timeline
  const tabValue = isTimelineMatch ? Constant.TAB_TIMELINE : isPetsMatch ? Constant.TAB_PETS : Constant.TAB_RESIDENT_PROFILE
  if (isTimelineMatch) {
    // if we are on timeline, we want to determine if we're on a click-through of the a timeline record
    const clickThroughMatch = matchPath(currentLocation.pathname, {path: Routes._MATCH_PROFILE_TIMELINE_GENERIC})
    if (clickThroughMatch) {
      // if we are, we want to find primary record id and the timeline title
      focusedPrimaryRecordId = clickThroughMatch.params.primaryRecordId

      // to fine the title, we find the matching entry in our timelineTitleLookup object by specific path
      const matchingClickThroughPath = Object.keys(timelineTitleLookup).find(p =>
        matchPath(currentLocation.pathname, {path: p, exact: true}),
      )
      // if we could find a key for this exact path, we record its associated title
      if (matchingClickThroughPath) {
        timelineTitle = timelineTitleLookup[matchingClickThroughPath]
      }
    }
  }

  const handleChangeTab = tab => {
    if (tab === tabValue || !focusedResidentId) {
      return
    }

    if (tab === Constant.TAB_TIMELINE) {
      props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE_TIMELINE, {residentId: focusedResidentId}))
    } else if (tab === Constant.TAB_PETS) {
      props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE_PETS, {residentId: focusedResidentId}))
    } else {
      props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: focusedResidentId}))
    }
  }

  const filterResidents = () => {
    const filterLowerCase = filterTerm.toLowerCase()
    return activeResidents.filter(resident => {
      return (
        !filterLowerCase ||
        resident.occupancy.unit.number.toLowerCase().includes(filterLowerCase) ||
        resident.displayName.toLowerCase().includes(filterLowerCase) ||
        resident.referenceId.toLowerCase().includes(filterLowerCase) ||
        (resident.occupancy.leaseToDate && resident.occupancy.leaseToDate.toLowerCase().includes(filterLowerCase))
      )
    })
  }

  const returnToResidentProfilesTable = () => {
    props.history.push(Routes.PROFILE)
  }

  const currentResident = focusedResidentId ? residentsLookup[focusedResidentId] : null

  let residentProfileComponent
  if (tabValue === Constant.TAB_RESIDENT_PROFILE) {
    if (focusedResidentId && isEditMatch) {
      residentProfileComponent = (
        <UserProfileEditor
          residentId={focusedResidentId}
          onCancel={() => props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: focusedResidentId}))}
          onSaved={() => props.history.push(Routes.constructPath(Routes.PROFILE_VIEW_SINGLE, {residentId: focusedResidentId}))}
        />
      )
    } else if (focusedResidentId && isProfileSingleMatch) {
      residentProfileComponent = <ProfileDetail residentId={focusedResidentId} />
    } else {
      residentProfileComponent = <ResidentsTable residents={filterResidents()} />
    }
  } else if (tabValue === Constant.TAB_TIMELINE) {
    if (!focusedPrimaryRecordId) {
      residentProfileComponent = (
        <ProfileTimeline filterState={filterState} setFilterState={setFilterState} residentId={focusedResidentId} />
      )
    } else {
      residentProfileComponent = (
        <div>
          <div className={classes.timelineContainer}>
            <div>
              <IconButton classes={{root: classes.leftIconButton}} onClick={() => props.history.goBack()} size="large">
                <RXRIcon icon={RXRIcon.ARROW_LEFT} color={Colors.rxrBlueColor} />
              </IconButton>
            </div>
            <div className={classes.timelineTitle}>{timelineTitle} </div>
          </div>
          <div className={classes.timelineComponent}>
            <Switch>
              <Route path={Routes.PROFILE_TIMELINE_CLEANING} render={() => <Cleaning vendorAppointmentId={focusedPrimaryRecordId} />} />
              <Route
                path={Routes.PROFILE_TIMELINE_DOG_WALKING}
                render={() => <DogWalking vendorAppointmentId={focusedPrimaryRecordId} />}
              />
              <Route path={Routes.PROFILE_TIMELINE_MASSAGE} render={() => <Massage vendorAppointmentId={focusedPrimaryRecordId} />} />
              <Route
                path={Routes.PROFILE_TIMELINE_PERSONAL_TRAINING}
                render={() => <PersonalTraining vendorAppointmentId={focusedPrimaryRecordId} />}
              />
              <Route
                path={Routes.PROFILE_TIMELINE_AMENITY_RESERVATION}
                render={() => <AmenityReservation amenityReservationId={focusedPrimaryRecordId} />}
              />
              <Route
                path={Routes.PROFILE_TIMELINE_GUEST_REGISTRATION}
                render={() => <GuestRegistration guestId={focusedPrimaryRecordId} />}
              />
              <Route
                path={Routes.PROFILE_TIMELINE_INCOMING_DELIVERY}
                render={() => <IncomingDelivery deliveryId={focusedPrimaryRecordId} />}
              />
              <Route
                path={Routes.PROFILE_TIMELINE_OUTGOING_DELIVERY}
                render={() => <OutgoingDelivery deliveryId={focusedPrimaryRecordId} />}
              />
              <Route
                path={Routes.PROFILE_TIMELINE_MAINTENANCE}
                render={() => <MaintenanceDetail serviceRequestId={focusedPrimaryRecordId} />}
              />
              <Route path={Routes.PROFILE_TIMELINE_PAYMENT} render={() => <Payment paymentId={focusedPrimaryRecordId} />} />
              <Route
                path={Routes.PROFILE_TIMELINE_BUILDING_ACTION}
                render={() => <ViewLogActionForm actionId={focusedPrimaryRecordId} />}
              />
            </Switch>
          </div>
        </div>
      )
    }
  } else if (tabValue === Constant.TAB_PETS) {
    residentProfileComponent = <PetsList className={classes.petsListContainer} occupancyId={currentResident.occupancy.id} />
  } else {
    residentProfileComponent = <EmptyStateIconAndMessage message={Constant.NO_RESIDENT_CONTENT} icon={RXRIcon.PROFILE} />
  }

  return (
    <div style={PageContainer}>
      <PageHeader title={!currentResident ? 'Resident Profiles' : ''}>
        {currentResident ? (
          <React.Fragment>
            <div className={classes.returnToResidentProfilesTableContainer}>
              <RXRButton
                type={RXRButton.TYPE_TEXT_SECONDARY}
                onClick={returnToResidentProfilesTable}
                className={classes.returnToResidentProfilesTable}
              >
                <RXRIcon icon={RXRIcon.ARROW_LEFT} color={rxrBlueColor} style={{paddingRight: spaceSmall}} />
                Resident Profiles
              </RXRButton>
            </div>
            <Grid item md={6} xs={12}>
              <div className={classes.residentNameIconContainer}>
                <ResidentProfileImage residentId={currentResident.id} className={classes.profileImage} />
                <div className={classes.residentNameUnitContainer}>
                  <Typography noWrap className={classes.residentName}>
                    {currentResident.displayName}
                    <ResidentStatusTag residentId={currentResident.id} className={classes.statusTag} />
                  </Typography>
                  <div style={{textAlign: 'left'}}>
                    {`UNIT - ${currentResident.occupancy ? currentResident.occupancy.unit.number : ''}`}
                  </div>
                </div>
              </div>
            </Grid>
          </React.Fragment>
        ) : null}
        {!currentResident ? (
          <Grid container spacing={3}>
            <Grid item className={classes.searchResidentContainer}>
              <ClearableInput value={filterTerm} onChange={setFilterTerm} placeholder={Constant.RESIDENT_SEARCH_PLACEHOLDER} />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid item lg={6} md={6}>
              <AntTabs value={tabValue} onChange={(e, t) => handleChangeTab(t)}>
                <AntTab label={tabConstants.RESIDENT_PROFILE} />
                <AntTab label={tabConstants.TIMELINE} />
                <AntTab label={tabConstants.PETS} />
              </AntTabs>
            </Grid>
          </Grid>
        )}
      </PageHeader>

      <div className={classes.contentContainer}>{residentProfileComponent}</div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  contentContainer: {
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    paddingBottom: spaceExtraLarge,
  },
  residentName: {
    color: rxrTealColor,
    fontSize: '24px',
    lineHeight: '24px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1.3px',
  },
  searchResidentContainer: {
    paddingBottom: spaceMedium,
    maxWidth: '420px',
    width: '100%',
  },
  residentNameIconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: spaceExtraSmall,
  },
  residentNameUnitContainer: {
    marginLeft: spaceMedium,
  },
  leftIconButton: {
    ...iconButton,
    marginRight: spaceSmall,
    width: RXRIcon.BUTTON_SIZE_LARGE,
    height: RXRIcon.BUTTON_SIZE_LARGE,
  },
  timelineComponent: {
    marginLeft: '40px',
    paddingBottom: '16px',
  },
  timelineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '24px',
    marginBottom: '24px',
    marginTop: '34px',
  },
  timelineTitle: {
    fontSize: 16,
    lineHeight: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: rxrBlueColor,
    letterSpacing: '0.8px',
  },
  profileImage: {
    flexShrink: 0,
    height: '135px',
    width: '135px',
    border: '2px solid',
    borderColor: rxrTealColor,
  },
  returnToResidentProfilesTable: {
    ...navigationButtonWithArrow,
  },
  returnToResidentProfilesTableContainer: {
    marginLeft: '-12px',
    marginTop: '-24px',
    marginBottom: spaceMedium,
  },

  petsListContainer: {
    padding: spaceLarge,
    maxWidth: '800px',
  },

  statusTag: {
    marginLeft: spaceMedium,
    textTransform: 'none',
    verticalAlign: '4px',
  },
}))

export default withRouter(UserProfile)
