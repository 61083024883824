import {
  AMENITY_RESERVATION_USE_CASE_MOVE_IN,
  AMENITY_RESERVATION_USE_CASE_MOVE_OUT,
  AMENITY_RESERVATION_USE_CASE_STAFF,
  AMENITY_RESERVATION_USE_CASE_UNIT_TRANSFER,
  ONBOARDING_AMENITY_KEY_PICKUP_LABEL,
} from '../constants/ModelConstants'
import AmenityConstants from '../components/Amenities/AmenitiesConstants'
import {Colors} from '../assets/styles'

/**
 * @param {Object} reservation
 * @return {string}
 */
export const getCalendarEventColor = reservation => {
  if (reservation.useCase) {
    switch (reservation.useCase) {
      case AMENITY_RESERVATION_USE_CASE_MOVE_IN:
        return Colors.rxrTealColor
      case AMENITY_RESERVATION_USE_CASE_MOVE_OUT:
        return Colors.rxrOrangeColor
      case AMENITY_RESERVATION_USE_CASE_UNIT_TRANSFER:
        return Colors.pink
      case AMENITY_RESERVATION_USE_CASE_STAFF:
        return Colors.rxrMediumGreyColor

      default:
      // do nothing, fall back to pending check below
    }
  }
  return reservation.reservationState === AmenityConstants.STATE_REQUESTED ? Colors.rxrOrangeColor : Colors.rxrTealColor
}

export const onboardingElevatorAmenityFilter = a => a.isOnboardingOnly && a.label !== ONBOARDING_AMENITY_KEY_PICKUP_LABEL
