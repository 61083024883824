import React from 'react'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import Grid from '@mui/material/Grid'
import {Typography as TypographyStyle, Colors} from './../../assets/styles'
import SelectInput from '../SelectInput'
import AmenityConstants from './AmenitiesConstants'
import ManyClosuresInput from './ManyClosuresInput'
import ManyGeneralAvailabilityInput from './ManyGeneralAvailabilityInput'
import PropTypes from 'prop-types'
import TextInput from '../TextInput'
import MultilineTextInput from '../MultilineTextInput'
import {spaceMedium} from '../../assets/styles/spacing'
import {useSelector} from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  formRow: {
    marginBottom: spaceMedium,
  },
  title: {
    ...TypographyStyle.H3Header,
    lineHeight: '22px',
    color: Colors.rxrBlackColor,
  },
}))

function ElevatorForm(props) {
  const isMobile = useSelector(state => state.App.isMobile)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>{AmenityConstants.ALL_FIELDS_REQUIRED}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_DETAILS}</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Amenity name"
            placeholder=""
            isRequired={true}
            maxLength={120}
            onChange={val => props.updateForm({label: val})}
            value={props.label}
            error={props.invalidItems.includes('label')}
          />
        </Grid>
        <Grid item xs={0} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <MultilineTextInput
            label="Description"
            placeholder=""
            rows={4}
            rowsMax={4}
            isRequired={true}
            x
            maxLength={10000}
            onChange={val => props.updateForm({description: val})}
            value={props.description}
            error={props.invalidItems.includes('description')}
          />
        </Grid>
        <Grid item xs={0} md={6}></Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            onChange={val =>
              props.updateForm({
                minReservationDuration: val,
                maxReservationDuration: val,
              })
            }
            label={'Reservation duration'}
            isRequired={true}
            options={AmenityConstants.MINIMUM_RESERVATION_OPTIONS}
            value={'' + props.minReservationDuration}
            // for elevators we are setting the same min and max reservations values
            error={props.invalidItems.includes('minReservationDuration') || props.invalidItems.includes('maxReservationDuration')}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_RESTRICTIONS}</Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{marginBottom: spaceMedium, maxWidth: isMobile ? '100%' : '75%'}}>
            <ManyGeneralAvailabilityInput
              value={props.generalAvailability}
              onChange={a => props.updateForm({generalAvailability: a})}
              onRemove={props.onRemoveGeneralAvailability}
              increment={props.reservationIncrement}
              error={props.invalidItems.includes('generalAvailability')}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.title}>{AmenityConstants.TITLE_CLOSURES}</Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{marginBottom: spaceMedium, maxWidth: isMobile ? '100%' : '75%'}}>
            <ManyClosuresInput value={props.closures} onChange={c => props.updateForm({closures: c})} onRemove={props.onRemoveClosure} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ElevatorForm.propTypes = {
  updateForm: PropTypes.func.isRequired,
  onRemoveGeneralAvailability: PropTypes.func.isRequired,
  onRemoveClosure: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  invalidItems: PropTypes.array.isRequired,
}

export default ElevatorForm
