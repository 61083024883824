import React, {useState, useEffect} from 'react'
import {Grid} from '@mui/material'
import SelectInput from '../../SelectInput'
import useFormChanged, {NonEmptyStringValidator, DateStringValidator} from '../../hooks/useFormChanged'
import {useDispatch, useSelector} from 'react-redux'
import {FormComponentPropTypes} from '../onboardingTypes'
import {getAmenityCalendar} from '../../../lib/queries'
import moment from 'moment'
import {convertAvailabilityToTimeRangeOptions} from '../../Amenities/amenityCalendarHelpers'
import DateInput from '../../DateInput'
import DashboardToggle from '../../DashboardToggle'
import useSharedStyles from '../useSharedStyles'
import {
  cancelAmenityReservation,
  createAmenityReservation,
  updateAmenityReservation,
  loadAmenitiesForBuilding,
} from '../../../actions/amenitiesActions'
import SaveOrEditFormCTAs from '../FormCTAs/SaveOrEditFormCTAs'
import {isOnboardingTaskStateCompleted} from '../../../Utils/onboardingUtils'
import {
  ONBOARDING_STATE_COMPLETED,
  ONBOARDING_STATE_COMPLETED_SKIPPED,
  ONBOARDING_STATE_PENDING_APPROVAL,
  ONBOARDING_STATE_CHANGES_REQUESTED,
  AMENITY_RESERVATION_USE_CASE_MOVE_IN,
} from '../../../constants/ModelConstants'
import RejectOrAcceptCTAs from '../FormCTAs/RejectOrAcceptCTAs'
import {selectOnboardingElevatorAmenitiesLookup} from '../../../reducers/selectors'

const EMPTY_FORM = {
  amenityId: null,
  startAt: null,
  isElevatorNeeded: true, // this goes on the form so formChanged will update when it's modified
  partySize: 1, // always party size 1
}

function ElevatorReservation(props) {
  const sharedClasses = useSharedStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [bookingDuration, setBookingDuration] = useState(0)
  const [options, setOptions] = useState([])
  const {setForm, form, formChanged, validateForm, invalidItems, resetInitialForm} = useFormChanged(
    EMPTY_FORM,
    useFormChanged.PropLevelValidation({
      amenityId: NonEmptyStringValidator,
      startAt: DateStringValidator,
    }),
  )
  const [reservationDate, setReservationDate] = useState(moment().add(1, 'day').toDate())
  const [isEditMode, setIsEditMode] = useState(false)
  const dispatch = useDispatch()

  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)
  const onboardingElevatorAmenitiesLookup = useSelector(selectOnboardingElevatorAmenitiesLookup)

  // NOTE: we don't need to load Amenity Reservations here because that request is already made periodically in BackgroundDataSync
  const amenityReservationsLookup = useSelector(state => state.Amenities.amenityReservationsLookup)

  const loadedReservation = props.submission.amenityReservationId
    ? amenityReservationsLookup[props.submission.amenityReservationId]
    : undefined

  useEffect(() => {
    if (loadedReservation) {
      resetInitialForm({...loadedReservation, isElevatorNeeded: true})
      setReservationDate(moment(loadedReservation.startAt).toDate())
    } else if (!props.submission.amenityReservationId) {
      resetInitialForm({...EMPTY_FORM, isElevatorNeeded: false})
    }
  }, [loadedReservation])

  const elevatorOptions = Object.values(onboardingElevatorAmenitiesLookup).map(a => ({label: a.label, value: a.id}))

  // if the number of elevator options changes (or when initialized) we need to change our form to match
  useEffect(() => {
    if (elevatorOptions.length === 0) {
      // if there are no elevators, it's probably because we haven't loaded amenities yet
      setIsLoading(true)
      loadAmenitiesForBuilding(dispatch, activeBuildingId).then(() => setIsLoading(false))
    } else if (elevatorOptions.length === 1) {
      // if there's exactly one elevator, we just pre-select it
      setForm({amenityId: elevatorOptions[0].value})
    } else {
      // do nothing, we're good
    }
  }, [elevatorOptions.length])

  // whenever the date or amenity changes, we need to reload the availability for that day & resource
  useEffect(() => {
    if (!form.amenityId || !reservationDate || isLoading) {
      return
    }

    setIsLoading(true)

    getAmenityCalendar(
      form.amenityId,
      props.submission.residentId,
      form.partySize,
      moment(reservationDate).startOf('day').toDate(),
      moment(reservationDate).endOf('day').toDate(),
      loadedReservation?.id,
    )
      .then(amenityCalendarDetail => {
        setBookingDuration(amenityCalendarDetail.minReservationDuration)
        const timeOptions = convertAvailabilityToTimeRangeOptions(
          amenityCalendarDetail.availability,
          amenityCalendarDetail.generalAvailability,
          amenityCalendarDetail.reservationIncrement,
          amenityCalendarDetail.minReservationDuration,
        )
        // every booking of an elevator will be the maximum duration
        setOptions(
          timeOptions.map(t => ({
            ...t,
            label: `${t.label} - ${moment(t.value).add(amenityCalendarDetail.minReservationDuration, 'minutes').format('hh:mm A')}`,
          })),
        )

        // determine what the timestamp would be for the new date at the same time
        const selectedTimeInMinutes = moment(form.startAt).diff(moment(form.startAt).startOf('day'), 'minutes')
        const thisTimeOnNewDay = moment(reservationDate).startOf('day').add(selectedTimeInMinutes, 'minutes').toISOString()

        // if that time is in our timeOptions list, we update our startAt accordingly
        if (timeOptions.some(t => t.value === thisTimeOnNewDay)) {
          setForm({startAt: thisTimeOnNewDay})
        } else {
          // if it's not, we reset the startAt to be empty
          setForm({startAt: null})
        }
      })
      .catch(err => {
        console.error(err)
        window.alert('Error getting amenity calendar: ' + err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [reservationDate, form.amenityId])

  const handleSave = async () => {
    // if the elevator is no longer needed, but we have a reservation loaded, we need to cancel it
    if (!form.isElevatorNeeded && form.id) {
      await cancelAmenityReservation(dispatch, form.id)
    }

    // at this point, we know we want to create or update an existing reservation,
    // make sure the form is valid to save
    if (!validateForm() && form.isElevatorNeeded) {
      return
    }

    try {
      let newReservationData
      const sharedInput = {
        startAt: form.startAt,
        endAt: moment(form.startAt).add(bookingDuration, 'minutes').toDate(),
        partySize: form.partySize,
      }

      // if they're changing the amenity or no longer need an elevator, we start by canceling the existing reservation
      if (loadedReservation && (form.amenityId !== loadedReservation.amenityId || !form.isElevatorNeeded)) {
        await cancelAmenityReservation(dispatch, form.id)
        // delete the form.id so the next condition knows to create a new one
        delete form.id
      }

      if (form.isElevatorNeeded) {
        if (form.id) {
          // if we already have a reservation id, we're updating an existing reservation
          newReservationData = await updateAmenityReservation(dispatch, {...sharedInput, id: form.id})
        } else {
          // otherwise, we're creating a new reservation
          newReservationData = await createAmenityReservation(dispatch, {
            ...sharedInput,
            amenityId: form.amenityId,
            residentId: props.submission.residentId,
            partySize: 1,
            useCase: AMENITY_RESERVATION_USE_CASE_MOVE_IN,
            notes: 'Move in elevator reservation',
          })
        }
      }

      if (form.isElevatorNeeded) {
        await props.onUpdateSubmission({
          ...props.submission,
          amenityReservationId: newReservationData.id,
          reservationStartAt: newReservationData.startAt,
          reservationEndAt: newReservationData.endAt,
          state: ONBOARDING_STATE_COMPLETED,
        })
      } else {
        await props.onUpdateSubmission({
          ...props.submission,
          amenityReservationId: null,
          reservationStartAt: null,
          reservationEndAt: null,
          state: ONBOARDING_STATE_COMPLETED_SKIPPED,
        })
      }

      setIsEditMode(false)
      // NOTE: we don't need to resetInitialForm here because it'll be handled by the loadedReservation useEffect once it realizes the submission object has changed
    } catch (err) {
      console.error(err)
      window.alert(err.message)
    }
  }

  const handleReject = async () => {
    if (loadedReservation && loadedReservation.id) {
      await cancelAmenityReservation(dispatch, form.id)
    }

    // update our submission state to CHANGES_REQUESTED
    props.onUpdateSubmission({
      ...props.submission,
      amenityReservationId: null,
      state: ONBOARDING_STATE_CHANGES_REQUESTED,
    })
  }

  const handleAccept = () => {
    // update our submission state to COMPLETED
    props.onUpdateSubmission({
      ...props.submission,
      state: ONBOARDING_STATE_COMPLETED,
    })
  }

  const isSubmissionPendingApproval = props.submission.state === ONBOARDING_STATE_PENDING_APPROVAL
  const canEditFields = isEditMode || !isOnboardingTaskStateCompleted(props.submission.state)

  return (
    <React.Fragment>
      <div className={sharedClasses.bodyContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DashboardToggle onChange={v => setForm({isElevatorNeeded: v})} value={form.isElevatorNeeded} label="Elevator needed?" />
          </Grid>

          {form.isElevatorNeeded && (
            <React.Fragment>
              <Grid item xs={12}>
                <SelectInput
                  onChange={id => setForm({amenityId: id})}
                  label={'Elevator selection'}
                  isRequired={true}
                  options={elevatorOptions}
                  value={form.amenityId}
                  error={invalidItems.includes('amenityId')}
                  disabled={!canEditFields || elevatorOptions.length === 1}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateInput
                  label={'Reservation date'}
                  value={reservationDate}
                  onChange={setReservationDate}
                  disabled={!canEditFields}
                  isLoading={isLoading}
                  min={moment().add(1, 'day').startOf('day').toDate()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectInput
                  onChange={dateStr => setForm({startAt: dateStr})}
                  label={'Reservation time'}
                  isRequired={true}
                  options={options}
                  value={form.startAt || undefined}
                  error={invalidItems.includes('startAt')}
                  isLoading={isLoading}
                  disabled={!canEditFields}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
      {isSubmissionPendingApproval ? (
        <RejectOrAcceptCTAs onAccept={handleAccept} onReject={handleReject} />
      ) : canEditFields ? (
        <SaveOrEditFormCTAs onSave={handleSave} onCancel={props.onCancel} hasChanges={formChanged} />
      ) : (
        <SaveOrEditFormCTAs onSave={() => setIsEditMode(true)} isEdit={true} />
      )}
    </React.Fragment>
  )
}

export default ElevatorReservation

ElevatorReservation.propTypes = FormComponentPropTypes
