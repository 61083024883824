import React, {useState, useRef} from 'react'
import {spaceExtraSmall, spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {makeStyles} from '@mui/styles'
import moment from 'moment'
import {rxrBlueColor, rxrLightGreyColor} from '../../../assets/styles/color'
import {borderRadiusMedium} from '../../../assets/styles/border'
import {NoteCardTitle, heavyFontWeight, lightFontWeight, BodyHeader, fontSizeMedium} from '../../../assets/styles/typography'
import RXRIcon from '../../RXRIcon'
import CreateNewNote from './CreateNewNote'
import {Menu, MenuItem} from '@mui/material'
import PropTypes from 'prop-types'
import {Colors} from '../../../assets/styles'

function NoteCard(props) {
  const classes = useStyles()
  const modifyRef = useRef()
  const [isModifyMenuOpen, setIsModifyMenuOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const stopEditing = () => {
    setIsEditing(false)
  }
  const modifyOptions = ['Edit', 'Delete']
  if (props.noteIsParent) {
    const pinText = props.note.isPinned ? 'Remove Pin' : 'Pin'
    modifyOptions.push(pinText)
  }
  return (
    <div
      className={classes.noteCardContainer}
      style={{
        padding: props.isSearchResult ? spaceMedium : undefined,
        borderRadius: props.isSearchResult ? borderRadiusMedium : undefined,
      }}
    >
      <div className={classes.titleContainer}>
        <div>
          {props.note.isPinned && <RXRIcon icon={RXRIcon.MAP_PIN} color={Colors.rxrTealColor} />}
          <div>
            <span className={classes.noteDetails}>
              {props.isSearchResult && props.searchTerm ? (
                props.note.staff.displayName.split(new RegExp(`(${props.searchTerm})`, 'gi')).map((part, index) =>
                  part.toLowerCase() === props.searchTerm.toLowerCase() ? (
                    <span key={index} style={{fontWeight: 'bold'}}>
                      {part}
                    </span>
                  ) : (
                    <span key={index}>{part}</span>
                  ),
                )
              ) : (
                <span className={classes.authorName}>{props.note.staff.displayName}</span>
              )}
              <span className={classes.dateTime}> -- {moment(props.note.createdAt).format('MM/DD/YY, h:mma')}</span>
            </span>
          </div>
        </div>
        {!props.isSearchResult && (
          <div
            className={classes.modifyNote}
            onClick={e => {
              e.stopPropagation()
              setIsModifyMenuOpen(!isModifyMenuOpen)
            }}
            ref={modifyRef}
          >
            <RXRIcon icon={RXRIcon.ELLIPSIS} />
            <Menu open={!!isModifyMenuOpen} anchorEl={modifyRef.current}>
              {modifyOptions.map(o => {
                return (
                  <MenuItem
                    key={o}
                    value={o}
                    onClick={() => {
                      if (o === 'Edit') {
                        handleEditClick()
                      } else if (o === 'Delete') {
                        props.handleDelete(props.note.id)
                      } else {
                        props.handleNotePin(props.note.id, props.note.isPinned)
                      }
                    }}
                  >
                    {o}
                  </MenuItem>
                )
              })}
            </Menu>
          </div>
        )}
      </div>
      {isEditing ? (
        <div className={classes.sendContainer}>
          <CreateNewNote
            onSave={props.onSave}
            editingNoteId={props.note.id}
            editingStartingContent={props.note.content}
            useCompact={true}
            stopEditing={stopEditing}
          />
        </div>
      ) : props.isSearchResult && props.searchTerm ? (
        <div className={classes.body}>
          {props.note.content.split(new RegExp(`(${props.searchTerm})`, 'gi')).map((part, index) =>
            part.toLowerCase() === props.searchTerm.toLowerCase() ? (
              <span key={index} style={{fontWeight: 'bold'}}>
                {part}
              </span>
            ) : (
              <span key={index}>{part}</span>
            ),
          )}
        </div>
      ) : (
        <div className={classes.body}>{props.note.content}</div>
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  noteCardContainer: {
    width: '100%',
    backgroundColor: rxrLightGreyColor,
    marginBottom: spaceMedium,
    overflow: 'hidden',
  },

  titleContainer: {
    marginBottom: spaceExtraSmall,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  modifyNote: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },

  authorName: {
    ...NoteCardTitle,
    fontWeight: heavyFontWeight,
  },

  dateTime: {
    ...NoteCardTitle,
    fontWeight: lightFontWeight,
  },

  body: {
    ...BodyHeader,
    marginBottom: spaceMedium,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },

  footer: {
    color: rxrBlueColor,
    size: fontSizeMedium,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  sendButton: {
    marginLeft: spaceSmall,
    flexGrow: 0,
    zIndex: 2,
    height: '40px',
    width: '40px',
    borderRadius: '20px',
    flexShrink: 0,
  },

  input: {
    width: '100%',
  },

  sendContainer: {
    width: '100%',
    marginBottom: spaceSmall,
  },
}))

NoteCard.propTypes = {
  note: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleNotePin: PropTypes.func,
  noteIsParent: PropTypes.bool,
  isSearchResult: PropTypes.bool,
  searchTerm: PropTypes.string,
}

export default NoteCard
