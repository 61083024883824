import {useEffect} from 'react'

/**
 * @param {string} key
 * @param {function} handler
 */
function useKeyPress(key, handler) {
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === key) {
        return handler(e)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [key, handler])
}

export default useKeyPress
