import SortableTable, {ColumnConfig} from '../SortableTable'
import moment from 'moment'
import {getCleaningSlotTime, getFrequencyQualities} from './servicesUtilities'
import React, {useEffect} from 'react'
import useResidentLookup from '../hooks/useResidentLookup'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import PropTypes from 'prop-types'
import {
  FREQUENCY_ONCE,
  FrequencyLabels,
  getVendorServiceTypeIcon,
  VendorServiceTypeMassage,
  VendorServiceTypePersonalTraining,
} from './ServicesConstants'
import {
  VENDOR_SERVICE_CANCELLED,
  VENDOR_SERVICE_COMPLETED,
  VENDOR_SERVICE_COMPLETED_PAID,
  VENDOR_SERVICE_CONFIRMED,
  VENDOR_SERVICE_CONFIRMED_APPROVED,
  VENDOR_SERVICE_DECLINED,
  VENDOR_SERVICE_REQUESTED,
  VENDOR_SERVICE_UNDERWAY,
  VENDOR_SERVICE_VENDOR_NO_SHOW,
  VENDOR_SERVICE_NEEDS_RESCHEDULE,
  appointmentShouldBeHighlightedAsNeedingReschedule,
} from './VendorServiceConstants'
import {capitalizeFirstLetter, pluralizeWord} from '../../Utils/StringFormatter'
import RXRIcon from '../RXRIcon'
import {makeStyles} from '@mui/styles'
import {Colors, Spacing} from '../../assets/styles'

const shared = {
  color: Colors.rxrBlackColor,
  border: `1px solid ${Colors.rxrBlackColor}`,
  borderRadius: '50%',
  padding: 1,
  height: 10,
  width: 10,
}

const useStyles = makeStyles(theme => ({
  cancelled: {
    ...shared,
    borderColor: Colors.rxrRedColor,
    color: Colors.rxrRedColor,
  },

  confirmed: {
    ...shared,
    borderColor: Colors.rxrGreenColor,
    color: Colors.rxrGreenColor,
  },

  completed: {
    ...shared,
    borderColor: Colors.rxrMediumGreyColor,
    color: Colors.rxrMediumGreyColor,
  },

  requested: {
    ...shared,
    borderColor: Colors.rxrYellowColor,
    color: Colors.rxrYellowColor,
  },
}))

const getstatusIcon = (inStatus, classes) => {
  switch (inStatus) {
    case VENDOR_SERVICE_CANCELLED:
    case VENDOR_SERVICE_DECLINED:
    case VENDOR_SERVICE_VENDOR_NO_SHOW:
      return <RXRIcon icon={RXRIcon.CLOSE} className={classes.cancelled} />

    case VENDOR_SERVICE_CONFIRMED_APPROVED:
    case VENDOR_SERVICE_UNDERWAY:
    case VENDOR_SERVICE_CONFIRMED:
      return <RXRIcon icon={RXRIcon.CHECK} className={classes.confirmed} />

    case VENDOR_SERVICE_COMPLETED_PAID:
    case VENDOR_SERVICE_COMPLETED:
      return <RXRIcon icon={RXRIcon.CHECK} className={classes.completed} />

    case VENDOR_SERVICE_REQUESTED:
    case VENDOR_SERVICE_NEEDS_RESCHEDULE:
      return <RXRIcon icon={RXRIcon.ELLIPSIS} className={classes.requested} />
  }
}

/**
 * @param {string} frequency
 * @param {Date} startAt
 */
function getFrequencyLabel(frequency, startAt) {
  if (frequency === FREQUENCY_ONCE) {
    return FrequencyLabels[frequency]
  }

  const [base, magnitude, weekDays] = getFrequencyQualities(frequency, startAt)

  // substr is to change "weekly" into "week"
  const cadence = capitalizeFirstLetter(pluralizeWord(base.substr(0, base.length - 2), magnitude, magnitude > 1))

  return `Every ${cadence} (${weekDays.map(dow => moment().startOf('week').add(dow, 'days').format('dd')).join(',')})`
}

function VendorAppointmentsTable(props) {
  const classes = useStyles()
  const {getResident} = useResidentLookup()
  const shouldRenderServiceNoLongerAvailableMessage = props.isServiceCurrentlyDisabled && !props.isArchiveTab
  const serviceNoLongerAvailableMessage = `This service is no longer available for residents.`

  let columns = [
    new ColumnConfig({
      title: 'Name',
      renderPrimitive: item => getResident(item.residentId).displayName,
      comparator: ColumnConfig.residentNameComparatorGenerator(getResident),
    }),
    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: item => getResident(item.residentId).occupancy.unit.number,
      comparator: ColumnConfig.residentUnitComparatorGenerator(getResident),
    }),
    new ColumnConfig({
      title: 'PTE',
      renderPrimitive: item => {
        const resident = getResident(item.residentId)
        return resident.userProfile && JSON.parse(resident.userProfile).cleaningPermission ? 'provided PTE' : 'did not provide PTE'
      },
      comparator: (a, b) => {
        const residentA = getResident(a.residentId)
        const residentB = getResident(b.residentId)
        let aVal = residentA.userProfile && JSON.parse(residentA.userProfile).cleaningPermission
        let bVal = residentB.userProfile && JSON.parse(residentB.userProfile).cleaningPermission

        return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
      },
    }),

    new ColumnConfig({
      title: 'Date',
      renderPrimitive: item => moment(item.startAt).format('M/D/YY'),
      comparator: ColumnConfig.simpleComparatorGenerator('startAt'),
      isDefaultSort: true,
      sortingDirection: props.isArchiveTab ? 'desc' : 'asc',
    }),

    new ColumnConfig({
      title: 'Time',
      renderPrimitive: item => getCleaningSlotTime(item.startAt, item.endAt),
    }),

    new ColumnConfig({
      title: 'Package',
      renderPrimitive: item => item.vendorService.label,
      comparator: ColumnConfig.simpleComparatorGenerator('vendorService.label'),
    }),

    new ColumnConfig({
      title: 'Recurring',
      renderPrimitive: item =>
        getFrequencyLabel(
          item.recurringVendorAppointment ? item.recurringVendorAppointment.frequency : FREQUENCY_ONCE,
          new Date(item.startAt),
        ),
      comparator: (a, b) => {
        const aRec = a.recurringVendorAppointment ? a.recurringVendorAppointment.frequency : ''
        const bRec = b.recurringVendorAppointment ? b.recurringVendorAppointment.frequency : ''

        return aRec < bRec ? -1 : aRec > bRec ? 1 : 0
      },
    }),

    new ColumnConfig({
      title: 'Cost',
      render: item => `$${item.cost}`,
      renderPrimitive: item => item.cost,
      comparator: ColumnConfig.simpleComparatorGenerator('cost'),
    }),

    new ColumnConfig({
      title: 'Status',
      render: (item, col) => (
        <div style={{whiteSpace: 'nowrap'}}>
          {getstatusIcon(item.status, classes)}
          &nbsp;&nbsp;
          <span style={{textTransform: 'capitalize'}}>{col.renderPrimitive(item)}</span>
        </div>
      ),
      renderPrimitive: item =>
        (item.paymentId
          ? `Payment: ${item.payment.status}`
          : item.status.includes('_')
          ? item.status.split('_').join(' ')
          : item.status
        ).toLowerCase(),
      comparator: ColumnConfig.simpleComparatorGenerator('status'),
    }),
  ]

  // hide PTE column for Massage and PT types
  if ([VendorServiceTypeMassage, VendorServiceTypePersonalTraining].includes(props.serviceType)) {
    columns = columns.filter(c => c.title !== 'PTE')
  }

  const disabledIcon = RXRIcon.NOT_ALLOWED
  const iconStr = getVendorServiceTypeIcon(props.serviceType)

  return props.vendorAppointments.length > 0 ? (
    <SortableTable
      key={`${props.isArchiveTab}`} // this key ensures the table gets unmounted on every tab change. We need this so the default sort order can reset
      data={props.vendorAppointments}
      columns={columns}
      onClickRow={props.onClickAppointment}
      downloadFileName={`${props.serviceType ? `${props.serviceType} ` : ''}Appointments`}
      rowHighlightCondition={appointmentShouldBeHighlightedAsNeedingReschedule}
    />
  ) : shouldRenderServiceNoLongerAvailableMessage ? (
    <EmptyStateIconAndMessage message={serviceNoLongerAvailableMessage} icon={disabledIcon} />
  ) : (
    <EmptyStateIconAndMessage message={'There are no appointments to show'} icon={iconStr} />
  )
}

VendorAppointmentsTable.propTypes = {
  onClickAppointment: PropTypes.func,
  serviceType: PropTypes.string,
  vendorAppointments: PropTypes.array.isRequired,
  isArchiveTab: PropTypes.bool,
  isServiceCurrentlyDisabled: PropTypes.bool,
}

export default VendorAppointmentsTable
