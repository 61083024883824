import React, {useState, useEffect, useRef} from 'react'
import EmptyStateIconAndMessage from '../../EmptyStateIconAndMessage'
import RXRIcon from '../../RXRIcon'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {spaceExtraLarge, spaceMedium, spaceSmall} from '../../../assets/styles/spacing'
import {MorningHeaderBlack} from '../../../assets/styles/typography'
import {useSelector, useDispatch} from 'react-redux'
import CreateNewNote from './CreateNewNote'
import NoteThread from './NoteThread'
import SimpleSpinner from '../../SimpleSpinner'
import QueryHelper from '../../../lib/QueryHelper'
import {ListStaffNotesForBuilding} from './queries'
import RXRButton from '../../RXRButton'
import moment from 'moment'
import {CRUD_ACTION_CREATE, CRUD_ACTION_UPDATE} from './constants'
import ClearableInput from '../../ClearableInput'
import {iterateSearchStaffNotes} from '../../../lib/queries'
import useDoOnceTimer from '../../hooks/useDoOnceTimer'
import NoteCard from './NoteCard'
import {rxrGreyColor, rxrTeal15Color} from '../../../assets/styles/color'
const CONTENT_SEARCH_TIMER = 'content-search-timer'

function StaffNotes() {
  const dispatch = useDispatch()
  const [isPerformingIterativeLoad, setIsPerformingIterativeLoad] = useState(true)
  const [isHandlingCRUDAction, setIsHandlingCRUDAction] = useState(false)
  const [staffNotes, setStaffNotes] = useState([])
  const [hasOlderStaffNotes, setHasOlderStaffNotes] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const searchTermFormatted = searchTerm.trim().toLowerCase()
  const [isContentSearching, setIsContentSearching] = useState(false)
  const [contentResults, setContentResults] = useState([])
  const [showMoreResults, setShowMoreResults] = useState(false)
  const contentSearch = useRef({search: '', iterator: null})
  const {setTimer, cancelTimer} = useDoOnceTimer()

  /**
   * The threadedNotesObject will have the structure
   * {
   *  id: { note: note, children: [note1, note2 ]}
   * }
   * */

  const [threadedNotesObject, setThreadedNotesObject] = useState({})

  const activeBuildingId = useSelector(state => state.Buildings.activeBuildingId)

  const olderNotesIterator = useRef()

  const handleClickSearchIcon = () => {
    setIsSearching(true)
  }

  function sortNotes(notes) {
    return notes.sort((a, b) => {
      // First, compare based on the pinned status
      if (a.isPinned !== b.isPinned) {
        return b.isPinned - a.isPinned
      }
      // If both have the same pinned status, compare by createdAt
      return moment(b.createdAt).diff(moment(a.createdAt))
    })
  }

  const iterativeLoadStaffNotes = async reset => {
    if (!reset && (!hasOlderStaffNotes || isPerformingIterativeLoad)) {
      return
    }

    setIsPerformingIterativeLoad(true)

    let updatedNotes = []
    const res = await olderNotesIterator.current.next()
    if (reset) {
      updatedNotes = res
    } else if (res.length > 0) {
      updatedNotes = [...staffNotes, ...res]
    } else {
      updatedNotes = [...staffNotes]
    }
    const updatedAndSortedNotes = sortNotes(updatedNotes)

    let newThreadedNotesObject = constructThreadedNotesObject(updatedAndSortedNotes || [])
    setThreadedNotesObject(newThreadedNotesObject)

    setStaffNotes(updatedAndSortedNotes)
    setHasOlderStaffNotes(!olderNotesIterator.current.hasCompleted)
    setIsPerformingIterativeLoad(false)
  }
  /**
   * @param  {StaffNoteFragment} updatedStaffNote
   * @param  {string} actionType // CRUD ACTIONS defined in StaffNotes/constants
   */
  const handleRefreshStaffNotesAfterCRUD = async (updatedStaffNote, actionType) => {
    setIsHandlingCRUDAction(true)

    try {
      let oldStaffNotes = staffNotes

      let updatedStaffNotes
      if (actionType === CRUD_ACTION_CREATE) {
        // Handle create
        updatedStaffNotes = [updatedStaffNote, ...oldStaffNotes]
      } else {
        // Handle update or delete
        // First, we find the index of the staff note that the user has just edited
        let indexOfEditedOrDeletedStaffNote = oldStaffNotes.findIndex(element => element.id === updatedStaffNote.id)
        if (actionType === CRUD_ACTION_UPDATE) {
          updatedStaffNotes = [
            ...oldStaffNotes.slice(0, indexOfEditedOrDeletedStaffNote),
            updatedStaffNote,
            ...oldStaffNotes.slice(indexOfEditedOrDeletedStaffNote + 1),
          ]
        } else {
          // in this scenario we know that the user has just deleted a staff note

          // first we remove our udpated staff note from the oldStaffNotes Array
          updatedStaffNotes = [
            ...oldStaffNotes.slice(0, indexOfEditedOrDeletedStaffNote),
            ...oldStaffNotes.slice(indexOfEditedOrDeletedStaffNote + 1),
          ]

          // next we find the index of all staff notes that has a staffNoteId (i.e. parent staffNoteId) equal to our deleted notes staff id
          let idxOfAllChildrenNotes = []
          for (const idx in updatedStaffNotes) {
            let currentStaffNoteInLoop = updatedStaffNotes[idx]
            if (currentStaffNoteInLoop.staffNoteId === updatedStaffNote.id) {
              idxOfAllChildrenNotes.push(Number(idx))
            }
          }

          // we reverse the idxOfAllChildrenNotes array so that we can delete the notes in the correct order
          idxOfAllChildrenNotes.reverse()

          // lastly we iterate over the idxOfAllChildrenNotes and remove the deleted notes from the updatedStaffNotes Array
          for (const idx in idxOfAllChildrenNotes) {
            const idxOfChildNote = idxOfAllChildrenNotes[idx]
            updatedStaffNotes = [...updatedStaffNotes.slice(0, idxOfChildNote), ...updatedStaffNotes.slice(idxOfChildNote + 1)]
          }
        }
      }
      const updatedAndSortedStaffNotes = sortNotes(updatedStaffNotes)
      setStaffNotes(updatedAndSortedStaffNotes)
      let newThreadedNotesObject = constructThreadedNotesObject(updatedAndSortedStaffNotes)
      setThreadedNotesObject(newThreadedNotesObject)
      setIsHandlingCRUDAction(false)
    } catch (err) {
      window.alert(err.message)
      setIsHandlingCRUDAction(false)
    }
  }

  /**
   * Convert the notesArray into an object with the structure
   * {
   *  noteId: { note: note, children: [note1, note2 ]}
   * }
   */
  const constructThreadedNotesObject = notesArray => {
    let threadedNotesObject = {}
    let childNotes = [...notesArray]
    let idxOfToBeDeletedNotesFromChildNotes = []
    notesArray.forEach((note, idx) => {
      if (!note.staffNoteId) {
        threadedNotesObject[note.id] = {note: note, children: []}
        idxOfToBeDeletedNotesFromChildNotes.push(idx)
      }
    })
    // The indices will be in order, but we want to delete from the back first to not run into issues with
    // indices later on, so we reverse the array
    idxOfToBeDeletedNotesFromChildNotes.reverse()
    idxOfToBeDeletedNotesFromChildNotes.forEach(idx => {
      childNotes.splice(idx, 1)
    })

    // Now we just need to iterate through the array of child notes and add them to the child array
    // of the appropriate parent note
    childNotes.forEach(note => {
      if (threadedNotesObject[note.staffNoteId]) {
        threadedNotesObject[note.staffNoteId].children.push(note)
      }
    })

    return threadedNotesObject
  }

  useEffect(() => {
    // if the term has not changed since our lister iterator, we bail (this could happen if they enter a space/delete a space)
    if (contentSearch.current.search === searchTermFormatted) {
      return
    }

    delete contentSearch.current.iterator
    contentSearch.current.search = searchTermFormatted
    setContentResults([])

    // must type at least 3 characters to search content
    if (searchTermFormatted.length < 3) {
      cancelTimer(CONTENT_SEARCH_TIMER)
      setShowMoreResults(false)
      setIsContentSearching(false)
      return
    }

    setShowMoreResults(false)
    setIsContentSearching(true)
    setTimer(CONTENT_SEARCH_TIMER, performContentSearch, 1000)
  }, [searchTermFormatted])

  function performContentSearch() {
    setIsContentSearching(true)
    if (!contentSearch.current.iterator) {
      contentSearch.current.iterator = iterateSearchStaffNotes(contentSearch.current.search, activeBuildingId)
    }

    contentSearch.current.iterator
      .next()
      .then(results => {
        setContentResults(prev => [...prev, ...results.filter(sn => sn.buildingId === activeBuildingId)])
      })
      .then(iterativeLoadStaffNotes)
      .then(() => {
        const staffNotesWithAuthorMatchingFilterTerm = staffNotes.filter(note =>
          note.staff.displayName.toLowerCase().includes(searchTermFormatted),
        )
        setContentResults(prev => {
          //  Filter out any notes from staffNotesWithAuthorMatchingFilterTerm that are already in contentResults
          const newResults = staffNotesWithAuthorMatchingFilterTerm.filter(note => !prev.find(n => n.id === note.id))
          const newContentResults = [...prev, ...newResults]
          // Sort the new content results by createdAt
          return newContentResults.sort((a, b) => {
            if (moment(b.createdAt).isAfter(a.createdAt)) {
              return 1
            } else if (moment(b.createdAt).isBefore(a.createdAt)) {
              return -1
            } else {
              return 0
            }
          })
        })
        setShowMoreResults(!contentSearch.current.iterator.hasCompleted || hasOlderStaffNotes)
      })
      .catch(e => {
        console.error(e)
      })
      .finally(() => {
        setIsContentSearching(false)
      })
  }

  useEffect(() => {
    if (activeBuildingId) {
      olderNotesIterator.current = QueryHelper.Instance().iterativeQuery(ListStaffNotesForBuilding, {
        buildingId: activeBuildingId,
        filter: {
          isDeleted: {ne: true},
        },
      })

      iterativeLoadStaffNotes(true).then()
    }
  }, [activeBuildingId])

  const classes = useStyles()
  return (
    <React.Fragment>
      {!isSearching ? (
        <div className={classes.flexRow}>
          <Typography className={classes.header}>NOTES</Typography>
          <div onClick={handleClickSearchIcon}>
            <RXRIcon icon={RXRIcon.SEARCH} />
          </div>
        </div>
      ) : (
        <ClearableInput placeholder={'Search notes...'} onChange={setSearchTerm} value={searchTerm} />
      )}
      <div className={classes.createNewNoteContainer}>
        <CreateNewNote onSave={handleRefreshStaffNotesAfterCRUD} placeholder={'Type a new note'} staffNoteId={null} />
      </div>

      {staffNotes.length === 0 ? (
        <EmptyStateIconAndMessage icon={RXRIcon.STAFF_NOTES} message={'No notes exist for this building'} iconHeight={50} iconWidth={50} />
      ) : searchTermFormatted ? (
        searchTermFormatted.length >= 3 ? (
          <>
            {isContentSearching && (
              <div className={classes.searchResult}>
                <SimpleSpinner /> <span style={{marginLeft: spaceSmall}}>Searching staff notes</span>
              </div>
            )}

            {!isContentSearching && contentResults.length === 0 && (
              <EmptyStateIconAndMessage icon={RXRIcon.STAFF_NOTES} message={'No notes found'} iconHeight={50} iconWidth={50} />
            )}
            {!isContentSearching && contentResults.length > 0 && (
              <div className={classes.innerNotesContainer}>
                {contentResults.map(note => {
                  return (
                    <NoteCard
                      key={note.id}
                      note={note}
                      onSave={handleRefreshStaffNotesAfterCRUD}
                      isSearchResult={true}
                      searchTerm={searchTermFormatted}
                    />
                  )
                })}
              </div>
            )}
            {!isContentSearching && showMoreResults && <RXRButton onClick={performContentSearch}>Show more results</RXRButton>}
          </>
        ) : (
          <EmptyStateIconAndMessage
            icon={RXRIcon.STAFF_NOTES}
            message={'Search query must be at least 3 characters long'}
            iconHeight={50}
            iconWidth={50}
          />
        )
      ) : isPerformingIterativeLoad && Object.keys(threadedNotesObject).length === 0 ? (
        <div className={classes.center}>
          <SimpleSpinner />
        </div>
      ) : (
        <div className={classes.innerNotesContainer}>
          {Object.entries(threadedNotesObject).map(tuple => {
            let parentNoteId = tuple[0]
            let thread = tuple[1]
            return <NoteThread key={parentNoteId} thread={thread} onSave={handleRefreshStaffNotesAfterCRUD} />
          })}
          {hasOlderStaffNotes &&
            !searchTermFormatted &&
            (isHandlingCRUDAction || isPerformingIterativeLoad ? (
              <div className={classes.center}>
                <SimpleSpinner />
              </div>
            ) : (
              <RXRButton onClick={() => iterativeLoadStaffNotes()}>Load older staff notes</RXRButton>
            ))}
        </div>
      )}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    ...MorningHeaderBlack,
  },

  center: {
    textAlign: 'center',
  },

  createNewNoteContainer: {
    paddingTop: spaceMedium,
    paddingBottom: spaceMedium,
  },

  innerNotesContainer: {},

  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },

  searchResult: {
    height: spaceExtraLarge,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default StaffNotes
