// ------------------------------------------------------------------------------
// CONVERSATION STATUS
export const CONVERSATION_CLOSED = 'CLOSED'
export const CONVERSATION_IN_PROGRESS = 'IN_PROGRESS'
export const CONVERSATION_OPEN = 'OPEN'
export const CONVERSATION_ARCHIVED = 'ARCHIVED'

// ------------------------------------------------------------------------------
// INVOICE STATUS
export const INVOICE_STATUS_ISSUED = 'PENDING_ISSUED'
export const INVOICE_STATUS_LATE = 'PENDING_LATE'
export const INVOICE_STATUS_PARTIAL = 'PENDING_PARTIAL'
export const INVOICE_STATUS_PARTIAL_LATE = 'PENDING_PARTIAL_LATE'
export const INVOICE_STATUS_PROCESSING = 'PENDING_PROCESSING'
export const INVOICE_STATUS_CANCELLED = 'COMPLETED_CANCELLED'
export const INVOICE_STATUS_PAID = 'COMPLETED_PAID'

// ------------------------------------------------------------------------------
// PAYMENT CARD STATUS
// * These UI statuses of the payment card on Home Page of App (not canonical statuses from the backend)
export const PAYMENT_CARD_DUE = 'PAYMENT_CARD_DUE'
export const PAYMENT_CARD_ERROR = 'PAYMENT_CARD_ERROR'
export const PAYMENT_CARD_PROCESSING = 'PAYMENT_CARD_PROCESSING'

// ------------------------------------------------------------------------------
// ONBOARDING
export const ONBOARDING_STATE_LOCKED = 'LOCKED'
export const ONBOARDING_STATE_AVAILABLE = 'AVAILABLE'
export const ONBOARDING_STATE_IN_PROGRESS = 'IN_PROGRESS'
export const ONBOARDING_STATE_PENDING_APPROVAL = 'PENDING_APPROVAL'
export const ONBOARDING_STATE_CHANGES_REQUESTED = 'CHANGES_REQUESTED'
export const ONBOARDING_STATE_COMPLETED = 'COMPLETED'
export const ONBOARDING_STATE_COMPLETED_AMENDING_PENDING_APPROVAL = 'COMPLETED_AMENDING_PENDING_APPROVAL'
export const ONBOARDING_STATE_COMPLETED_AMENDING_CHANGES_REQUESTED = 'COMPLETED_AMENDING_CHANGES_REQUESTED'
export const ONBOARDING_STATE_COMPLETED_SKIPPED = 'COMPLETED_SKIPPED'

export const ONBOARDING_STATE_LOCKED_PLAIN_ENGLISH = 'Locked'
export const ONBOARDING_STATE_AVAILABLE_PLAIN_ENGLISH = 'Available'
export const ONBOARDING_STATE_IN_PROGRESS_PLAIN_ENGLISH = 'In progress'
export const ONBOARDING_STATE_PENDING_APPROVAL_PLAIN_ENGLISH = 'Pending approval'
export const ONBOARDING_STATE_CHANGES_REQUESTED_PLAIN_ENGLISH = 'Changes requested'
export const ONBOARDING_STATE_COMPLETED_PLAIN_ENGLISH = 'Completed'
export const ONBOARDING_STATE_COMPLETED_AMENDING_PENDING_APPROVAL_PLAIN_ENGLISH = 'Completed - Pending approval'
export const ONBOARDING_STATE_COMPLETED_AMENDING_CHANGES_REQUESTED_PLAIN_ENGLISH = 'Completed - Changes Requested'
export const ONBOARDING_STATE_COMPLETED_SKIPPED_PLAIN_ENGLISH = 'Completed - Skipped'

export const MAP_ONBOARDING_STATE_TO_PLAIN_ENGLISH = {
  [ONBOARDING_STATE_LOCKED]: ONBOARDING_STATE_LOCKED_PLAIN_ENGLISH,
  [ONBOARDING_STATE_AVAILABLE]: ONBOARDING_STATE_AVAILABLE_PLAIN_ENGLISH,
  [ONBOARDING_STATE_IN_PROGRESS]: ONBOARDING_STATE_IN_PROGRESS_PLAIN_ENGLISH,
  [ONBOARDING_STATE_PENDING_APPROVAL]: ONBOARDING_STATE_PENDING_APPROVAL_PLAIN_ENGLISH,
  [ONBOARDING_STATE_CHANGES_REQUESTED]: ONBOARDING_STATE_CHANGES_REQUESTED_PLAIN_ENGLISH,
  [ONBOARDING_STATE_COMPLETED]: ONBOARDING_STATE_COMPLETED_PLAIN_ENGLISH,
  [ONBOARDING_STATE_COMPLETED_AMENDING_PENDING_APPROVAL]: ONBOARDING_STATE_COMPLETED_AMENDING_PENDING_APPROVAL_PLAIN_ENGLISH,
  [ONBOARDING_STATE_COMPLETED_AMENDING_CHANGES_REQUESTED]: ONBOARDING_STATE_COMPLETED_AMENDING_CHANGES_REQUESTED_PLAIN_ENGLISH,
  [ONBOARDING_STATE_COMPLETED_SKIPPED]: ONBOARDING_STATE_COMPLETED_SKIPPED_PLAIN_ENGLISH,
}

export const ONBOARDING_IMPORTANCE_OPTIONAL = 'OPTIONAL'
export const ONBOARDING_IMPORTANCE_REQUIRED = 'REQUIRED'
export const ONBOARDING_IMPORTANCE_CRITICAL = 'CRITICAL'

export const ONBOARDING_AMENITY_KEY_PICKUP_LABEL = 'Key pick-up'

// ------------------------------------------------------------------------------
// OCCUPANCY STATUS
export const OCCUPANCY_STATUS_FUTURE = 'Future'
export const OCCUPANCY_STATUS_PAST = 'Past'

// ------------------------------------------------------------------------------
// FEEDBACK TYPES
export const FEEDBACK_TYPE_NET_PROMOTER_SCORE = 'NET_PROMOTER_SCORE'
export const FEEDBACK_TYPE_AMENITY_RESERVATION = 'AMENITY_RESERVATION'
export const FEEDBACK_TYPE_MAINTENANCE_REQUEST = 'MAINTENANCE_REQUEST'
export const FEEDBACK_TYPE_VENDOR_APPOINTMENT = 'VENDOR_APPOINTMENT'
export const FEEDBACK_TYPE_PROBABILITY_OF_RENEWAL = 'PROBABILITY_OF_RENEWAL'
export const FEEDBACK_TYPE_OVERALL_SENTIMENT = 'OVERALL_SENTIMENT'

// ------------------------------------------------------------------------------
// CORE FEATURES -- APP SETTINGS
// These are the various strings we expect to see listed in a building's disabledFeature list

export const CORE_FEATURE_PACKAGE_DELIVERY = 'packageDelivery'
export const CORE_FEATURE_CLEANINGS = 'cleanings'
export const CORE_FEATURE_AMENITIES = 'amenities'
export const CORE_FEATURE_PAL = 'PAL'
export const CORE_FEATURE_RENT = 'rent'
export const CORE_FEATURE_MAINTENACE = 'serviceRequests'
export const CORE_FEATURE_RXR_VOLUNTEER = 'rxrVolunteer'
export const CORE_FEATURE_DOG_WALKING = 'dogWalking'
export const CORE_FEATURE_MASSAGE = 'massage'
export const CORE_FEATURE_PERSONAL_TRAINING = 'personalTraining'
//this feature will determine whether the app disables specific features for Korman properties
export const CORE_FEATURE_KORMAN = 'korman'
export const CORE_FEATURE_SMART_HUB = 'smartHub'
export const CORE_FEATURE_DATA_COLLECTION = 'dataCollection'
export const CORE_FEATURE_ELEVATORS = 'elevatorBooking'

// ------------------------------------------------------------------------------
// STAFF PERMISSION GROUPS

export const PERMISSION_GROUP_SUPER_ADMIN = 'admin'
export const PERMISSION_GROUP_NOT_RESTRICTED = 'staff'
export const PERMISSION_GROUP_LIGHTLY_RESTRICTED = 'group2'
export const PERMISSION_GROUP_MODERATELY_RESTRICTED = 'group3'
export const PERMISSION_GROUP_HIGHLY_RESTRICTED = 'group4'

export const PERMISSION_GROUP_VENDOR = 'vendor'
// This is technically not a permission group (it is a flag on the BuildingStaff table), but we will treat it as such for purposes of the Settings -> User table
export const PERMISSION_GROUP_FORMER_USER = 'isDisabled'

// ------------------------------------------------------------------------------
// NOTIFICATION SETTINGS CONSTANTS

export const NOTIFICATION_CATEGORY_AMENITY_RESERVATION = 'AMENITY_RESERVATION'
export const NOTIFICATION_CATEGORY_BUILDING = 'BUILDING'
export const NOTIFICATION_CATEGORY_CHAT_MESSAGE = 'CHAT_MESSAGE'
export const NOTIFICATION_CATEGORY_CLEANING = 'CLEANING'
export const NOTIFICATION_CATEGORY_DOG_WALKING = 'DOG_WALKING'
export const NOTIFICATION_CATEGORY_SPA_SERVICES = 'SPA_SERVICES'
export const NOTIFICATION_CATEGORY_PERSONAL_TRAINING = 'PERSONAL_TRAINING'
export const NOTIFICATION_CATEGORY_DELIVERY = 'DELIVERY'
export const NOTIFICATION_CATEGORY_EMERGENCY = 'EMERGENCY'
export const NOTIFICATION_CATEGORY_EVENT = 'EVENT'
export const NOTIFICATION_CATEGORY_GUEST = 'GUEST'
export const NOTIFICATION_CATEGORY_MAINTENANCE = 'MAINTENANCE'
export const NOTIFICATION_CATEGORY_PAYMENT = 'PAYMENT'
export const NOTIFICATION_CATEGORY_NPS = 'NPS'
export const NOTIFICATION_CATEGORY_SERVICE_SURVEY = 'SERVICE_SURVEY'
export const NOTIFICATION_CATEGORY_RXO_UNREAD_RESIDENT_MESSAGE = 'RXOUnreadResidentMessage'
export const NOTIFICATION_CATEGORY_RXO_DELAYED_CHAT_RESPONSE = 'RXODelayedChatResponse'
export const NOTIFICATION_CATEGORY_RXO_STAFF_AMENITY_RESERVATION_REQUEST = 'RXOStaffAmenityReservationRequest'

export const NOTIFICATION_MEDIUM_PUSH = 'PUSH'
export const NOTIFICATION_MEDIUM_SMS = 'SMS'
export const NOTIFICATION_MEDIUM_CALL = 'CALL'
export const NOTIFICATION_MEDIUM_EMAIL = 'EMAIL'

// ------------------------------------------------------------------------------
// AMENITY RESERVATION CONSTANTS

export const AMENITY_RESERVATION_USE_CASE_MOVE_IN = 'MOVE_IN'
export const AMENITY_RESERVATION_USE_CASE_MOVE_OUT = 'MOVE_OUT'
export const AMENITY_RESERVATION_USE_CASE_STAFF = 'STAFF'
export const AMENITY_RESERVATION_USE_CASE_UNIT_TRANSFER = 'UNIT_TRANSFER'
